.search-bar {
    width: 500;
    height: 100;
    border-radius: 30;
    background-color: red;
    position: relative;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-top {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 10px;
}
.center-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.container {
    width: 100%;
    height: 100%;
    margin-top: 150px;
    padding-left: 10%;
    padding-right: 10%;
}

/* Used this to generate linear-gradient: https: //en.web-tool.org/css-linear-gradient/ */
.header {
    position: fixed;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    background: linear-gradient(180deg, rgba(216, 0, 0, 0.19) 0%, rgba(255, 155, 80, 0.66) 100%);
}

.center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-icon {
    width: auto;
    height: 38px;
    border-radius:  20px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

}


/* stylin for carousel */
.rec.rec-slider-container {
    margin-left: 0px;
    margin-right: 0px;
   border-radius: 10px;
   /* border-top-right-radius: 10px; */
}